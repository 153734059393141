import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import sViaTravelSubHeader from './viaTravelSubHeader.scss';

function ViaTravelSubHeader({ betaLinks = false }) {
  const [toggleDetails, setToggleDetails] = useState(false);

  return (
    <>
      <div className={sViaTravelSubHeader.subheader}>
        <div className={sViaTravelSubHeader['subheader-content']}>
          <div className={sViaTravelSubHeader.item}>
            <NavLink
              className={sViaTravelSubHeader.selection}
              to={!betaLinks ? '/plans/plan-list' : '/plans/plan-list-beta'}
              activeClassName={sViaTravelSubHeader.selected}
            >
              Plans
            </NavLink>
          </div>
          <div className={sViaTravelSubHeader.item}>
            <NavLink
              className={sViaTravelSubHeader.selection}
              to={!betaLinks ? '/plans/traveler-list' : '/plans/traveler-list-beta'}
              activeClassName={sViaTravelSubHeader.selected}
            >
              Travelers
            </NavLink>
          </div>
          <div className={sViaTravelSubHeader.item}>
            <NavLink
              className={sViaTravelSubHeader.selection}
              to={!betaLinks ? '/safecheck/messages' : '/safecheck/messages-beta'}
              activeClassName={sViaTravelSubHeader.selected}
            >
              SafeCheck
            </NavLink>
          </div>
          <div className={sViaTravelSubHeader.item}>
            <Dropdown isOpen={toggleDetails} toggle={() => setToggleDetails(!toggleDetails)} data-value="menu">
              <DropdownToggle caret className={`${sViaTravelSubHeader.transparent}`}>
                Details
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem className={sViaTravelSubHeader.customDropdown}>
                  <NavLink
                    className={sViaTravelSubHeader.selection}
                    to={
                      !betaLinks
                        ? '/plans/transportation-details-dashboard'
                        : '/plans/transportation-details-dashboard-beta'
                    }
                    activeClassName={sViaTravelSubHeader.selected}
                  >
                    Transportation Details
                  </NavLink>
                </DropdownItem>
                <DropdownItem className={sViaTravelSubHeader.customDropdown}>
                  <NavLink
                    className={sViaTravelSubHeader.selection}
                    to={!betaLinks ? '/plans/housing-details-dashboard' : '/plans/housing-details-dashboard-beta'}
                    activeClassName={sViaTravelSubHeader.selected}
                  >
                    Housing Details
                  </NavLink>
                </DropdownItem>
                <DropdownItem className={sViaTravelSubHeader.customDropdown}>
                  <NavLink
                    className={sViaTravelSubHeader.selection}
                    to={!betaLinks ? '/plans/activity-details-dashboard' : '/plans/activity-details-dashboard-beta'}
                    activeClassName={sViaTravelSubHeader.selected}
                  >
                    Activity Details
                  </NavLink>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </div>
    </>
  );
}

export default withStyles(sViaTravelSubHeader)(ViaTravelSubHeader);

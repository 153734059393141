exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _2E5Ka{to{-webkit-transform:rotate(1turn)}}@keyframes _2E5Ka{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._2JxNU{margin:20px;font-family:AvenirNextRegular,sans-serif;font-size:28px;line-height:40px;color:#043544;padding:0 40px 128px;padding:0 2.5rem 8rem}", ""]);

// exports
exports.locals = {
	"container": "_2JxNU",
	"spin": "_2E5Ka"
};
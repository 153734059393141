import React from 'react';
import styled from 'styled-components';

const SectionHeaderDiv = styled.div`
  color: ${({ theme }) => theme.sectionHeader.fontColor};
  line-height: ${({ theme }) => theme.sectionHeader.lineHeight};
  display: flex;
  flex-direction: row;

  &:after {
    content: '';
    flex: 1 1;
    border-bottom: ${({ theme }) => theme.sectionHeader.line};
    margin: auto;
  }
`;

const SectionHeaderH2 = styled.h2`
  color: ${({ theme }) => theme.sectionHeader.fontColor};
  font-family: ${({ theme }) => theme.sectionHeader.fontFamily};
  font-size: ${({ theme }) => theme.sectionHeader.fontSize};
  font-weight: 500;
  line-height: ${({ theme }) => theme.sectionHeader.lineHeight};
  margin-bottom: 0;
  padding: ${({ theme }) => theme.sectionHeader.textPadding};
`;

const SectionHeader = ({ text }) => (
  <SectionHeaderDiv>
    <SectionHeaderH2>
      {text}
    </SectionHeaderH2>
  </SectionHeaderDiv>
);

export default SectionHeader;

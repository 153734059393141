import React from 'react';
import { componentFromProp, compose, defaultProps } from 'recompose';
import classnames from 'classnames';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import sHeadline from './headline.scss';

export const enhance = compose(withStyles(sHeadline));

function Headline(props) {
  const InnerHeadline = defaultProps({ tag: props.tag })(componentFromProp('tag'));
  const style = `headline-${props.as || props.tag}`;

  return (
    <>
      <InnerHeadline
        className={classnames(
          sHeadline[style],
          props.bold ? sHeadline['bold'] : '',
          props.upper ? sHeadline['upper'] : '',
          props.gray ? sHeadline['gray'] : '',
          props.red ? sHeadline['red'] : '',
          props.centered ? sHeadline['centered'] : '',
          props.underline ? sHeadline['underline'] : '',
          props.italic ? sHeadline['italic'] : '',
          props.font ? sHeadline[props.font] : '',
        )}
        {...props}
      >
        {props.children}
      </InnerHeadline>
    </>
  );
}

Headline.propTypes = {
  tag: propTypes.oneOf(['h1', 'h2', 'h3', 'h3', 'h4', 'h5', 'h6']),
  as: propTypes.oneOf(['h1', 'h2', 'h3', 'h3', 'h4', 'h5', 'h6']),
  underline: propTypes.bool,
  centered: propTypes.bool,
  upper: propTypes.bool,
  bold: propTypes.bool,
  italic: propTypes.bool,
  gray: propTypes.bool,
  font: propTypes.oneOf(['avenirMedium', 'introBoldItalic']),
};

Headline.defaultProps = {
  tag: 'h1',
  as: 'h2',
  underline: false,
  centered: false,
  upper: false,
  bold: false,
  italic: false,
  gray: false,
  font: 'introBoldItalic',
};

export default enhance(Headline);

exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes zguTT{to{-webkit-transform:rotate(1turn)}}@keyframes zguTT{to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}._3d2OL{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;width:100%;border-left:2px solid #ebebeb;padding:0 16px;margin-bottom:52px}._3d2OL ._1LKWD{-ms-flex:1 1;flex:1 1}._3d2OL ._1d9wx,._3d2OL ._2fYsQ,._3d2OL ._3qdWD{-ms-flex:1 1;flex:1 1;padding-top:32px}._3d2OL ._3qdWD ._3mQSv{text-decoration:underline;cursor:pointer}._3d2OL ._1Ip4n{-ms-flex:1 1;flex:1 1;padding:32px 12px 0 0}", ""]);

// exports
exports.locals = {
	"ov-right-nav": "_3d2OL",
	"ov-right-nav-dates": "_1LKWD",
	"ov-right-nav-locations": "_2fYsQ",
	"ov-right-nav-type": "_1d9wx",
	"ov-right-nav-program": "_3qdWD",
	"ov-right-nav-associate-program": "_3mQSv",
	"ov-right-nav-action": "_1Ip4n",
	"spin": "zguTT"
};
import React from 'react';

export default function LocationIcon() {
  return (
    <svg width="28" height="25" viewBox="0 0 28 25" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <path d="M13.5244 11.2786C14.4564 11.2786 15.2119 10.607 15.2119 9.77856C15.2119 8.95014 14.4564 8.27856 13.5244 8.27856C12.5924 8.27856 11.8369 8.95014 11.8369 9.77856C11.8369 10.607 12.5924 11.2786 13.5244 11.2786Z" />
      <path d="M13.5244 2.27856C8.87219 2.27856 5.08691 5.49653 5.08691 9.45044C5.08691 11.3334 6.05248 13.8375 7.95672 16.8933C9.48602 19.3467 11.2553 21.5653 12.1755 22.6692C12.331 22.8578 12.5343 23.0112 12.7689 23.1169C13.0036 23.2227 13.2629 23.2778 13.526 23.2778C13.7891 23.2778 14.0484 23.2227 14.2831 23.1169C14.5177 23.0112 14.721 22.8578 14.8765 22.6692C15.7952 21.5653 17.566 19.3467 19.0953 16.8933C20.9963 13.8384 21.9619 11.3343 21.9619 9.45044C21.9619 5.49653 18.1766 2.27856 13.5244 2.27856ZM13.5244 12.7786C12.8569 12.7786 12.2044 12.6026 11.6494 12.273C11.0943 11.9433 10.6618 11.4748 10.4063 10.9266C10.1509 10.3784 10.084 9.77524 10.2143 9.19329C10.3445 8.61135 10.6659 8.0768 11.1379 7.65724C11.6099 7.23769 12.2113 6.95196 12.866 6.83621C13.5207 6.72045 14.1993 6.77986 14.816 7.00693C15.4327 7.23399 15.9598 7.61851 16.3306 8.11185C16.7015 8.6052 16.8994 9.18522 16.8994 9.77856C16.8984 10.5739 16.5425 11.3365 15.9098 11.8989C15.2771 12.4613 14.4192 12.7777 13.5244 12.7786Z" />
    </svg>
  );
}

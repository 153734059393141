import React, { useState } from 'react';
import { compose } from 'recompose';
import { useSelector } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import propTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import ViaModal from '../../../../components/library/viaModal';
import Button from '../../../../components/library/button';
import Paragraph from '../../../../components/library/paragraph';
import RegistrationStatusSelect from '../../../../../sites/plans/components/selects/registrationStatusSelect';
import sBatchUpdatePlanRegistrationStatus from './batchUpdatePlanRegistrationStatus.scss';
import OneClickModal from '../oneClickModal';

export const enhance = compose(withStyles(sBatchUpdatePlanRegistrationStatus));

function BatchUpdatePlanRegistrationStatus(props) {
  const [showUpdatePlanRegistrationStatus, setShowUpdatePlanRegistrationStatus] = useState(false);
  const [submitValues, setSubmitValues] = useState();
  const customAliases = useSelector(state => state.profile.customAliases);
  const batchUpdatePlanRegistrationStatus = useSelector(state => state.plans.batchUpdatePlanRegistrationStatus);

  const [defaultValues, setDefaultValues] = useState(() => {
    let values = {
      plan_registration_status_id: '',
    };
    return values;
  });

  const resetForm = () => {
    setDefaultValues(() => {
      let values = {
        plan_registration_status_id: '',
      };
      return values;
    });
  };

  let customStyles = {
    multiValue: provided => ({
      ...provided,
    }),
    option: (provided, state) => ({
      ...provided,
    }),
    container: (provided, state) => ({
      ...provided,
    }),
    placeholder: provided => ({
      ...provided,
      color: '#373737',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: '#373737',
    }),
    control: provided => ({
      ...provided,
      borderColor: '#ebebeb',
      width: '550px',
      minHeight: '44px',
    }),
  };

  return (
    <>
      <ViaModal
        showing={props.show}
        onClose={() => {
          resetForm();
          props.onClose(false);
        }}
        headerText="Update Registration Status"
        width="700px"
      >
        <div className={sBatchUpdatePlanRegistrationStatus['batch-update-plan-registration-status']}>
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={defaultValues}
            validate={values => {
              const errors = {};

              if (!values.plan_registration_status_id) {
                errors.plan_registration_status_id = 'Status is required';
              }
              return errors;
            }}
            onSubmit={values => {
              const submitValues = {
                plan_ids: props.selectedPlans,
                new_status_id: values.plan_registration_status_id,
              };

              setSubmitValues(submitValues);
              props.onClose(false);
              setShowUpdatePlanRegistrationStatus(true);
            }}
          >
            {({ values, handleSubmit, errors }) => (
              <Form>
                <div className={sBatchUpdatePlanRegistrationStatus.section}>
                  <div className={sBatchUpdatePlanRegistrationStatus.row}>
                    <div className={sBatchUpdatePlanRegistrationStatus.instructions}>
                      <Paragraph size="normal">
                        Select a status from the drop down to update the selected plans.
                      </Paragraph>
                    </div>
                  </div>
                </div>{' '}
                <div className={sBatchUpdatePlanRegistrationStatus.section}>
                  <div className={sBatchUpdatePlanRegistrationStatus.row}>
                    <div className={sBatchUpdatePlanRegistrationStatus.item}>
                      <Field name="plan_registration_status_id">
                        {({ field, form }) => (
                          <RegistrationStatusSelect
                            labelText="Registration Status"
                            isRequired
                            id="plan_registration_status_id"
                            name="plan_registration_status_id"
                            styles={customStyles}
                            defaultValue={{
                              value: defaultValues.plan_registration_status_id,
                              label: defaultValues.plan_registration_status_name,
                            }}
                            errorMessage={errors.plan_registration_status_id}
                            onChange={e => {
                              form.setFieldValue('plan_registration_status_id', e.value);
                            }}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                </div>
                <div className={sBatchUpdatePlanRegistrationStatus.footer}>
                  <div className={sBatchUpdatePlanRegistrationStatus['button-content']}>
                    <Button
                      display="secondary"
                      kind="solid"
                      size="medium"
                      ariaLabel="Cancel Button"
                      onClick={() => {
                        props.onClose(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className={sBatchUpdatePlanRegistrationStatus['button-content']}>
                    <Button
                      display={!values.plan_registration_status_id ? 'darkGrayDisabled' : 'primary'}
                      kind="solid"
                      size="medium"
                      type="submit"
                      ariaLabel="Update Plan Registration Status Button"
                      disabled={!values.plan_registration_status_id ? true : false}
                    >
                      Continue
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </ViaModal>
      <OneClickModal
        show={showUpdatePlanRegistrationStatus}
        onClose={() => {
          resetForm();
          setShowUpdatePlanRegistrationStatus(false);
          props.setSelectedPlans([]);
        }}
        paragraphOne={`You are about to update the registration status of  ${props.selectedPlans.length} plans. ${
          customAliases.alias_travelers
        } are not able to join cancelled or closed plans. `}
        paragraphTwo={'Are you sure you want to update?'}
        paragraphTwoDemiBold
        cancelMessage={'Cancel'}
        confirmActionMessage={'Update'}
        headline={'Update Registration Status'}
        source="batch_update_registration_status"
        selectedPrograms={props.selectedPlans}
        actionState={batchUpdatePlanRegistrationStatus}
        batchUpdatePlanRegistrationStatusSuccess={` ${props.selectedPlans.length} ${
          props.selectedPlans.length === 1 ? `plan` : `plans`
        } updated`}
        centered
        submitValues={submitValues}
        width="700px"
      />
    </>
  );
}

BatchUpdatePlanRegistrationStatus.propTypes = {
  show: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
};

export default enhance(BatchUpdatePlanRegistrationStatus);

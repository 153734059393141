import React from 'react';
import { compose } from 'redux';
import { PropTypes } from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import sAccessDenied from './AccessDenied.scss';

const AccessDenied = props => {
  let superUsers = props.admins.filter(
    admin => admin.role === 'super_user' && admin.first_name !== '' && admin.last_name !== '',
  );
  return (
    <div className={sAccessDenied.container}>
      <p className={sAccessDenied.warning}>
        {!!props.clientFeatureList
          ? `Your account does not have permissions to view Via Global.`
          : `Your role has not been granted permission to view this part of the site.`}
      </p>
      <p className={sAccessDenied.warning}>
        {!!props.clientFeatureList
          ? `To upgrade your account to have Via Global features, please contact your Via Account Manager.`
          : `Roles & Permissions are changed at the discretion of the account Super ${
              superUsers.length > 1 ? 'Users' : 'User'
            }.`}
      </p>
      <p className={sAccessDenied.warning}>
        {!!props.clientFeatureList
          ? ''
          : `For more information, 
        ${
          superUsers.length > 0
            ? ` reach out to the following: ${superUsers.map(user => ` ${user.first_name} ${user.last_name}`)}.`
            : ' reach out to your Super User(s).'
        }`}
      </p>
    </div>
  );
};

export default compose(withStyles(sAccessDenied))(AccessDenied);

import React from 'react';
import {
  getFilterCountries,
  getFilterPlanTypes,
  getFilterGenderIdentities,
  getFilterTransportationTypes,
  getFilterActivityTypes,
  getFilterHousingTypes,
  getFilterAdmins,
  getFilterTrackingStepOptions,
  getFilterYearInSchoolList,
  getFilterTravelerPayForProgramList,
  getFilterSubjectAreaList,
  getFilterProgramTypes,
  getFilterLanguageList,
  getFilterPreferredHousingTypeList,
  getFilterTravelerCertaintyList,
  getFilterTravelerGoalList,
  getFilterTravelerBarrierList,
  getFilterApplicationTagList,
  getFilterApplicationStatusOptions,
  getFilterTermNameOptions,
  getFilterProgramTermTagList,
  getFilterAllProgramNameList,
  getFilterInternalProgramNameList,
  getFilterTravelerTypeList,
  getFilterResidencyStatusList,
  getFilterRaceList,
  getFilterFormTagList,
  getFilterProgramTermTagListAll,
  getFilterAuthorizedProgramNameList,
  getFilterUnauthorizedProgramNameList,
} from '../customFilters/AgGridViewHelpers';

const filterRace = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        suppressSorting: true,
        values: params => {
          getFilterRaceList().then(data => {
            setTimeout(() => {
              data.unshift(' (Blanks)');
              params.success(data);
            }, 1);
          });
        },
      },
    },
  ],
};

const filterRaceApp = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        suppressSorting: true,
        values: params => {
          getFilterRaceList().then(data => {
            setTimeout(() => {
              data.unshift('Not Provided');
              data.unshift('Not Requested');
              data.unshift(' (Blanks)');
              params.success(data);
            }, 1);
          });
        },
      },
    },
  ],
};

const filterResidencyStatus = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        suppressSorting: true,
        values: params => {
          getFilterResidencyStatusList().then(data => {
            setTimeout(() => {
              data.unshift(' (Blanks)');
              params.success(data);
            }, 1);
          });
        },
      },
    },
  ],
};

const filterResidencyStatusApp = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        suppressSorting: true,
        values: params => {
          getFilterResidencyStatusList().then(data => {
            setTimeout(() => {
              data.unshift('Not Provided');
              data.unshift('Not Requested');
              data.unshift(' (Blanks)');
              params.success(data);
            }, 1);
          });
        },
      },
    },
  ],
};

const filterTravelerType = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        suppressSorting: true,
        values: params => {
          getFilterTravelerTypeList().then(data => {
            setTimeout(() => {
              data.unshift(' (Blanks)');
              params.success(data);
            }, 1);
          });
        },
      },
    },
  ],
};

const filterTravelerTypeApp = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        suppressSorting: true,
        values: params => {
          getFilterTravelerTypeList().then(data => {
            setTimeout(() => {
              data.unshift('Not Provided');
              data.unshift('Not Requested');
              data.unshift(' (Blanks)');
              params.success(data);
            }, 1);
          });
        },
      },
    },
  ],
};

const filterProgramNameList = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        values: params => {
          getFilterAllProgramNameList().then(data => {
            setTimeout(() => {
              data.unshift(' (Blanks)');
              params.success(data);
            }, 1);
          });
        },
      },
    },
  ],
};

const filterProgramNameInternalList = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        values: params => {
          getFilterInternalProgramNameList().then(data => {
            setTimeout(() => {
              data.unshift(' (Blanks)');
              params.success(data);
            }, 1);
          });
        },
      },
    },
  ],
};

const filterAuthorizedProgramNameList = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        values: params => {
          getFilterAuthorizedProgramNameList().then(data => {
            setTimeout(() => {
              data.unshift(' (Blanks)');
              params.success(data);
            }, 1);
          });
        },
      },
    },
  ],
};

const filterUnauthorizedProgramNameList = orgId => {
  return {
    filters: [
      {
        filter: 'agTextColumnFilter',
      },
      {
        filter: 'agSetColumnFilter',
        filterParams: {
          values: params => {
            getFilterUnauthorizedProgramNameList(orgId).then(data => {
              setTimeout(() => {
                data.unshift(' (Blanks)');
                params.success(data);
              }, 1);
            });
          },
        },
      },
    ],
  };
};

const filterProgramTermTagList = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        values: params => {
          getFilterProgramTermTagList().then(data => {
            setTimeout(() => {
              data.unshift(' (Blanks)');
              params.success(data);
            }, 1);
          });
        },
      },
    },
  ],
};

const filterProgramTermTagListAll = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        values: params => {
          getFilterProgramTermTagListAll().then(data => {
            setTimeout(() => {
              data.unshift(' (Blanks)');
              params.success(data);
            }, 1);
          });
        },
      },
    },
  ],
};

const filterTermNameOptions = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        values: params => {
          getFilterTermNameOptions().then(data => {
            setTimeout(() => {
              data.unshift(' (Blanks)');
              params.success(data);
            }, 1);
          });
        },
      },
    },
  ],
};

const filterApplicationStatusOptions = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        values: params => {
          getFilterApplicationStatusOptions().then(data => {
            setTimeout(() => {
              data.unshift(' (Blanks)');
              params.success(data);
            }, 1);
          });
        },
      },
    },
  ],
};

const filterFormStatusOptions = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      suppressSorting: true,
      filterParams: {
        values: params => {
          params.success(['Not Started', 'Started', 'Submitted', 'Accepted']);
        },
      },
    },
  ],
};

const filterApplicationTagList = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        values: params => {
          getFilterApplicationTagList().then(data => {
            setTimeout(() => {
              data.unshift(' (Blanks)');
              params.success(data);
            }, 1);
          });
        },
      },
    },
  ],
};

const filterFormTagList = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        values: params => {
          getFilterFormTagList().then(data => {
            setTimeout(() => {
              data.unshift(' (Blanks)');
              params.success(data);
            }, 1);
          });
        },
      },
    },
  ],
};

const filterTravelerBarrierList = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        values: params => {
          getFilterTravelerBarrierList().then(data => {
            setTimeout(() => {
              data.unshift(' (Blanks)');
              params.success(data);
            }, 1);
          });
        },
      },
    },
  ],
};

const filterTravelerGoalList = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        values: params => {
          getFilterTravelerGoalList().then(data => {
            setTimeout(() => {
              data.unshift(' (Blanks)');
              params.success(data);
            }, 1);
          });
        },
      },
    },
  ],
};

const filterTravelerCertaintyList = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        values: params => {
          getFilterTravelerCertaintyList().then(data => {
            setTimeout(() => {
              data.unshift(' (Blanks)');
              params.success(data);
            }, 1);
          });
        },
      },
    },
  ],
};

const filterPreferredHousingTypeList = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        values: params => {
          getFilterPreferredHousingTypeList().then(data => {
            setTimeout(() => {
              data.unshift(' (Blanks)');
              params.success(data);
            }, 1);
          });
        },
      },
    },
  ],
};

const filterLanguageList = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        values: params => {
          getFilterLanguageList().then(data => {
            setTimeout(() => {
              data.unshift(' (Blanks)');
              params.success(data);
            }, 1);
          });
        },
      },
    },
  ],
};

const filterProgramTypeList = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        values: params => {
          getFilterProgramTypes().then(data => {
            setTimeout(() => {
              data.unshift(' (Blanks)');
              params.success(data);
            }, 1);
          });
        },
      },
    },
  ],
};

const filterSubjectAreaList = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        values: params => {
          getFilterSubjectAreaList().then(data => {
            setTimeout(() => {
              data.unshift(' (Blanks)');
              params.success(data);
            }, 1);
          });
        },
      },
    },
  ],
};

const filterTravelerPayForProgram = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        values: params => {
          getFilterTravelerPayForProgramList().then(data => {
            setTimeout(() => {
              data.unshift(' (Blanks)');
              params.success(data);
            }, 1);
          });
        },
      },
    },
  ],
};

const filterYearInSchool = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        values: params => {
          getFilterYearInSchoolList().then(data => {
            setTimeout(() => {
              data.unshift(' (Blanks)');
              params.success(data);
            }, 1);
          });
        },
      },
    },
  ],
};

const filterTrackingSteps = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        values: params => {
          getFilterTrackingStepOptions().then(data => {
            setTimeout(() => {
              data.unshift(' (Blanks)');
              params.success(data);
            }, 1);
          });
        },
      },
    },
  ],
};

const filterTravelerTrackingSteps = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        values: params => {
          getFilterTrackingStepOptions().then(data => {
            setTimeout(() => {
              data.unshift('Interested');
              data.unshift(' (Blanks)');
              params.success(data);
            }, 1);
          });
        },
      },
    },
  ],
};

const filterParamsCountries = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        values: params => {
          getFilterCountries().then(data => {
            setTimeout(() => {
              data.unshift(' (Blanks)');
              params.success(data);
            }, 1);
          });
        },
      },
    },
  ],
};

const filterParamsCountriesApp = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        suppressSorting: true,
        values: params => {
          getFilterCountries().then(data => {
            setTimeout(() => {
              data.unshift('Not Provided');
              data.unshift('Not Requested');
              data.unshift(' (Blanks)');
              params.success(data);
            }, 1);
          });
        },
      },
    },
  ],
};

const filterPlanTypes = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        values: params => {
          getFilterPlanTypes().then(data => {
            setTimeout(() => {
              data.unshift(' (Blanks)');
              params.success(data);
            }, 1);
          });
        },
      },
    },
  ],
};

const filterGenderIdentities = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        values: params => {
          getFilterGenderIdentities().then(data => {
            setTimeout(() => {
              data.unshift(' (Blanks)');
              params.success(data);
            }, 1);
          });
        },
      },
    },
  ],
};

const filterTransportationTypes = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        values: params => {
          getFilterTransportationTypes().then(data => {
            setTimeout(() => {
              data.unshift(' (Blanks)');
              params.success(data);
            }, 1);
          });
        },
      },
    },
  ],
};

const filterHousingTypes = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        values: params => {
          getFilterHousingTypes().then(data => {
            setTimeout(() => {
              data.unshift(' (Blanks)');
              params.success(data);
            }, 1);
          });
        },
      },
    },
  ],
};

const filterActivityTypes = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        values: params => {
          getFilterActivityTypes().then(data => {
            setTimeout(() => {
              data.unshift(' (Blanks)');
              params.success(data);
            }, 1);
          });
        },
      },
    },
  ],
};

const filterAdmins = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        suppressSorting: true,
        values: params => {
          getFilterAdmins().then(data => {
            setTimeout(() => {
              data.unshift(' (Blanks)');
              params.success(data);
            }, 1);
          });
        },
      },
    },
  ],
};

const filterPlanStatus = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        suppressSorting: true,
        values: params => {
          params.success([' (Blanks)', 'Approved', 'Not Approved', 'Pending Approval', 'Registered']);
        },
      },
    },
  ],
};

const filterRegistrationStatus = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        suppressSorting: true,
        values: params => {
          params.success([' (Blanks)', 'Cancelled', 'Closed', 'Complete', 'Open']);
        },
      },
    },
  ],
};

const filterEventRegistrationStatus = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        suppressSorting: true,
        values: params => {
          params.success(['Invited', 'Registered']);
        },
      },
    },
  ],
};

const filterEventAttendanceStatus = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        suppressSorting: true,
        values: params => {
          params.success(['Attended', 'Not Attended']);
        },
      },
    },
  ],
};

const filterAvailability = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        suppressSorting: true,
        values: params => {
          params.success(['Private', 'Public']);
        },
      },
    },
  ],
};

const filterTravelType = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        suppressSorting: true,
        values: params => {
          params.success(['Group', 'Solo']);
        },
      },
    },
  ],
};

const filterContinent = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        suppressSorting: true,
        values: params => {
          params.success([
            ' (Blanks)',
            'Africa',
            'Antarctica',
            'Asia',
            'Europe',
            'North America',
            'Oceania',
            'South America',
          ]);
        },
      },
    },
  ],
};

const filterTiming = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        suppressSorting: true,
        values: params => {
          params.success([' (Blanks)', 'Upcoming', 'On-site', 'Past']);
        },
      },
    },
  ],
};

const filterEventTiming = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        suppressSorting: true,
        values: params => {
          params.success(['Upcoming', 'Today', 'Past']);
        },
      },
    },
  ],
};

const filterEventState = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        suppressSorting: true,
        values: params => {
          params.success(['Active', 'Cancelled']);
        },
      },
    },
  ],
};

const filterTravelerStatus = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        suppressSorting: true,
        values: params => {
          params.success(['Active', 'Inactive']);
        },
      },
    },
  ],
};

const filterRole = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        suppressSorting: true,
        values: params => {
          params.success(['Traveler', 'Group Leader']);
        },
      },
    },
  ],
};

const filterSafecheckStatus = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        suppressSorting: true,
        values: params => {
          params.success([' (Blanks)', 'Enrolled', 'Not Enrolled', 'Opted Out']);
        },
      },
    },
  ],
};

const filterTravelStatus = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        suppressSorting: true,
        values: params => {
          params.success(['Going', 'Not Going']);
        },
      },
    },
  ],
};

const filterTravelerSSO = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        suppressSorting: true,
        values: params => {
          params.success(['SSO', 'Non-SSO']);
        },
      },
    },
  ],
};

const filterRelationshipType = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        suppressSorting: true,
        values: params => {
          params.success([
            ' (Blanks)',
            'Mother',
            'Father',
            'Parent',
            'Grandparent',
            'Mentor',
            'Sister',
            'Brother',
            'Teacher',
            'Other Family Member',
            'Other',
          ]);
        },
      },
    },
  ],
};

const filterPassportStatus = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        suppressSorting: true,
        values: params => {
          params.success([' (Blanks)', 'Current', 'Expired', 'Do Not Know', 'Do Not Have', 'Not Provided', 'Unknown']);
        },
      },
    },
  ],
};

const filterPassportStatusApp = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        suppressSorting: true,
        values: params => {
          params.success([
            ' (Blanks)',
            'Current',
            'Expired',
            'Do Not Know',
            'Do Not Have',
            'Not Provided',
            'Not Requested',
            'Unknown',
          ]);
        },
      },
    },
  ],
};

const filterEthnicity = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        suppressSorting: true,
        values: params => {
          params.success([' (Blanks)', 'Hispanic/Latin of any Race', 'Not Hispanic or Latino']);
        },
      },
    },
  ],
};

const filterSexAssigned = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        suppressSorting: true,
        values: params => {
          params.success([' (Blanks)', 'Male', 'Female', 'Other']);
        },
      },
    },
  ],
};

const filterInternalAuthorized = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        suppressSorting: true,
        values: params => {
          params.success([' (Blanks)', 'Internal', 'Authorized']);
        },
      },
    },
  ],
};

const filterYesNoOptions = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        suppressSorting: true,
        values: params => {
          params.success(['Yes', 'No']);
        },
      },
    },
  ],
};

const filterYesNoBlanksOptions = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        suppressSorting: true,
        values: params => {
          params.success([' (Blanks)', 'Yes', 'No']);
        },
      },
    },
  ],
};

const filterFirstGenerationStatueOptions = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        suppressSorting: true,
        values: params => {
          params.success([' (Blanks)', 'Yes', 'No', 'I’m Not Certain', 'Prefer Not to Answer']);
        },
      },
    },
  ],
};

const filterReceivesFinancialAidOptions = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        suppressSorting: true,
        values: params => {
          params.success([' (Blanks)', 'Yes', 'No', 'Not Sure']);
        },
      },
    },
  ],
};

const filterLanguageImmersionOptions = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        suppressSorting: true,
        values: params => {
          params.success([' (Blanks)', 'Yes', 'No', 'Not Sure']);
        },
      },
    },
  ],
};

const filterSafecheckResponse = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        suppressSorting: true,
        values: params => {
          params.success(['Yes', 'No', 'No Reply']);
        },
      },
    },
  ],
};

const filterMessageAllowLocation = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        suppressSorting: true,
        values: params => {
          params.success(['Yes', 'No', 'Not Requested']);
        },
      },
    },
  ],
};

const filterTravelerTitles = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        suppressSorting: true,
        values: params => {
          params.success([' (Blanks)', 'Mr.', 'Ms.', 'Mrs.', 'Mx.', 'Dr.']);
        },
      },
    },
  ],
};

const filterDefaultMultiOptions = {
  filter: 'agMultiColumnFilter',
  filterParams: {
    filters: [
      {
        filter: 'agTextColumnFilter',
      },
      {
        filter: 'agSetColumnFilter',
      },
    ],
  },
};

const filterTermStatus = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        suppressSorting: true,
        values: params => {
          params.success([' (Blanks)', 'Active', 'Cancelled']);
        },
      },
    },
  ],
};

const filterProgramState = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        suppressSorting: true,
        values: params => {
          params.success([' (Blanks)', 'Archived', 'Draft', 'Published', 'Unpublished']);
        },
      },
    },
  ],
};

const filterAmenitiesList = {
  filters: [
    {
      filter: 'agTextColumnFilter',
    },
    {
      filter: 'agSetColumnFilter',
      filterParams: {
        suppressSorting: true,
        values: params => {
          params.success([
            ' (Blanks)',
            'Administrative fees',
            'Airfare',
            'Airport drop-off',
            'Airport pickup',
            'Application fees',
            'Books & supplies',
            'Carbon offset',
            'Cell/mobile phone',
            'Country visa or permits',
            'Custom internship placement',
            'Excursion transportation',
            'Excursions',
            'Housing',
            'International medical insurance',
            'Internet access in housing',
            'Internet access on site',
            'Language training',
            'Laundry',
            'Local transportation',
            'Meals',
            'Meals on excursions',
            'Official transcript',
            'On-site orientation',
            'On-site staff support',
            'Passport application expenses',
            'Personal expenses',
            'Pre-departure advising',
            'Refundable deposit',
            'Some meals',
            'Tips for local guides',
            'Travel insurance',
            'Tuition',
          ]);
        },
      },
    },
  ],
};

export {
  filterDefaultMultiOptions,
  filterTiming,
  filterContinent,
  filterTravelType,
  filterAvailability,
  filterRegistrationStatus,
  filterPlanStatus,
  filterParamsCountries,
  filterPlanTypes,
  filterRole,
  filterSafecheckStatus,
  filterTravelStatus,
  filterTravelerType,
  filterPassportStatus,
  filterResidencyStatus,
  filterResidencyStatusApp,
  filterRace,
  filterRaceApp,
  filterSexAssigned,
  filterGenderIdentities,
  filterEthnicity,
  filterYesNoOptions,
  filterYesNoBlanksOptions,
  filterSafecheckResponse,
  filterMessageAllowLocation,
  filterTransportationTypes,
  filterHousingTypes,
  filterActivityTypes,
  filterEventTiming,
  filterEventState,
  filterEventRegistrationStatus,
  filterEventAttendanceStatus,
  filterTravelerTitles,
  filterAdmins,
  filterTrackingSteps,
  filterTravelerSSO,
  filterYearInSchool,
  filterRelationshipType,
  filterTravelerStatus,
  filterTravelerPayForProgram,
  filterReceivesFinancialAidOptions,
  filterSubjectAreaList,
  filterProgramTypeList,
  filterLanguageImmersionOptions,
  filterLanguageList,
  filterPreferredHousingTypeList,
  filterTravelerCertaintyList,
  filterTravelerGoalList,
  filterTravelerBarrierList,
  filterTravelerTrackingSteps,
  filterApplicationTagList,
  filterApplicationStatusOptions,
  filterTermNameOptions,
  filterProgramTermTagList,
  filterTermStatus,
  filterProgramNameList,
  filterInternalAuthorized,
  filterParamsCountriesApp,
  filterTravelerTypeApp,
  filterPassportStatusApp,
  filterFirstGenerationStatueOptions,
  filterFormStatusOptions,
  filterFormTagList,
  filterProgramState,
  filterProgramTermTagListAll,
  filterAmenitiesList,
  filterAuthorizedProgramNameList,
  filterProgramNameInternalList,
  filterUnauthorizedProgramNameList,
};

import React, { lazy, Suspense, useEffect, useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import _get from 'lodash/get';
import StyledIcon from '../../../../../styledComponents/styles/Icon.styled';
import TextInput from '../../../../../styledComponents/styles/TextInput.styled';
import Toggle from '../../../../../styledComponents/styles/Toggle.styled';
import { AccentButtonMediumNunito } from '../../../../../styledComponents/styles/Buttons.styled';
import { AccentButtonSpinner } from '../../../../../styledComponents/styles/spinner.styled';
import { updateForm, updateQuestion, getForms, getTokens } from '../../../../../actions/formsActions';
import DropdownSelect from '../../../../../styledComponents/styles/DropdownSelect.styled';
import EditorDropdown from '../../../../components/v2/EditorDropdown';

const EditTextArea = lazy(() => import('../../../../../styledComponents/styles/EditTextArea.styled'));

const DropdownContainer = styled.div`
  flex: 1;
  margin: 20px 0;
  text-align: left;
`;

const FormContainer = styled.div`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #373737;
`;

const InputContainer = styled.div`
  flex: 1;
  margin: 20px 0;
  text-align: left;
`;

const SubmitContainer = styled.main`
  display: flex;
  justify-content: flex-end;
  margin-top: 35px;
`;

const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: inherit;
`;

const ToggleLabel = styled.span`
  align-self: center;
  flex: 1;
  justify-content: center;
  padding-left: 10px;
  padding-bottom: 7px;
`;

export default function QuestionRecommendationAddEdit({ question, edit, onClose }) {
  const MAX_LENGTH = 90;
  const qIdentifier = 'recommendation';
  const dispatch = useDispatch();
  const fetchForm = useSelector(state => state.forms.fetchForm);
  const questionTypesInfo = useSelector(state => state.forms.questionTypes);
  const updateFormSubmit = useSelector(state => state.forms.updateForm);
  const updateQuestionSubmit = useSelector(state => state.forms.updateQuestion);
  const tokens = useSelector(state => state.forms.token); 
  const [formId, setFormId] = useState(null);
  const forms = useSelector(state => state.forms.forms);
  const [data, setData] = useState([]);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [sendBodyToken, setSendBodyToken] = useState('');

  useEffect(() => {
    dispatch(getForms('Recommendation', 1));
    dispatch(getTokens("", 'Recommendation')); 
  }, []);


  const addBodyToken = value => {
    setSendBodyToken(value);
  };

  useEffect(() => {
    if (sendBodyToken) {
      setSendBodyToken('');
    }
  }, [sendBodyToken]);



  useEffect(() => {
    if (!forms?.loading && forms?.data?.[0]?.template_type?.toLowerCase() === 'recommendation') {
      const setOptions = forms.data.map(item => {
        return item.default
          ? { value: item.id, label: item.name, default: item.default }
          : { value: item.id, label: item.name };
      });

      const defaultOption = setOptions.find(option => !!option.default);
      if (!edit) {
        setValue('recommendation_template_id', defaultOption.value.toString(), {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        });
      }

      setData(setOptions);
    }
  }, [forms?.data]);

  const schema = yup.object().shape({
    recommendation_label: yup
      .string()
      .required('Question must have label')
      .max(MAX_LENGTH, `Label can not be more than ${MAX_LENGTH} characters`),
    recommendation_instructions_applicant: yup
      .string()
      .min(9, 'Instructions for Applicant is required')
      .required('Instructions for Applicant is required'),
    recommendation_instructions_recommender: yup
      .string()
      .min(9, 'Instructions for Recommender is required')
      .required('Instructions for Recommender is required'),
    recommendations: yup.boolean().required(''),
    recommendation_required: yup.boolean(),
    recommendation_template_id: yup.string().required('Recommendation template is required.'),
  });

  const { control, handleSubmit, formState, setValue, getValues } = useForm({
    shouldUnregister: false,
    resolver: yupResolver(schema),
    defaultValues: {
      recommendation_label: edit ? question.label : '',
      recommendation_instructions_applicant: edit ? question.applicant_instructions || '' : '',
      recommendation_instructions_recommender: edit ? question.recommender_instructions || '' : '',
      recommendations: false,
      recommendation_required: edit ? question.required : true,
      recommendation_template_id: edit ? question.recommendation_template_id : '',
    },
    mode: 'onChange',
  });

  useEffect(() => {
    if (!fetchForm?.loading && fetchForm?.data) {
      setDisableSubmit(false);
      setFormId(fetchForm.data.id);
    }
  }, [fetchForm]);

  useEffect(() => {
    if (
      (!updateFormSubmit?.loading && updateFormSubmit?.data) ||
      (!updateQuestionSubmit?.loading && updateQuestionSubmit?.data)
    ) {
      setDisableSubmit(false);
      onClose();
    }
  }, [updateFormSubmit, updateQuestionSubmit]);

  const onSubmitHandler = data => {
    if (questionTypesInfo && questionTypesInfo.data) {
      const questionType = questionTypesInfo.data.reduce((prev, curr) => {
        return prev || curr.attributes.question_types.find(question => question.identifier === qIdentifier);
      }, undefined);


      // filter the recommend instructions 
      let instructionRecommendationText = data.recommendation_instructions_recommender.replace(/<p><\/p>/g, '<br />');
      instructionRecommendationText = instructionRecommendationText.replace(/\n/g, '');
      instructionRecommendationText = instructionRecommendationText.replace(/<br><\/p>/g, '</p><br>');
      instructionRecommendationText = instructionRecommendationText.replace(/<p><\/p>/g, '<br />');
      //end

      const values = {
        admin_only: false,
        applicant_instructions: data.recommendation_instructions_applicant,
        recommender_instructions: instructionRecommendationText,
        label: data.recommendation_label,
        required: data.recommendation_required,
        recommendation_template_id: data.recommendation_template_id,
      };

      if (formId && data && (questionType?.id || question?.id)) {
        //update or save new
        const actionToCall = edit ? updateQuestion : updateForm;
        const idToUpdate = edit ? question.id : questionType.id;
        setDisableSubmit(true);
        dispatch(actionToCall(formId, questionType.endpoint, idToUpdate, values));
      }
    }
  };

  return (
    <FormContainer>
      <form id={'recommendationAdd'} onSubmit={handleSubmit(onSubmitHandler)}>
        <Controller
          control={control}
          name={'recommendation_label'}
          render={({ field: { ref, ...rest } }) => (
            <InputContainer>
              <TextInput
                aria-label={'Recommendation Label'}
                errorMsg={_get(formState.errors.recommendation_label, 'message') || ''}
                id={'recommendation_label'}
                label={'Recommendation Label'}
                openSans
                placeholder={'Enter Label'}
                required
                {...rest}
              />
            </InputContainer>
          )}
        />
        <Controller
          control={control}
          name={'recommendation_instructions_applicant'}
          render={({ field: { ref, ...rest } }) => (
            <InputContainer>
              <Suspense fallback={<div />}>
                <EditTextArea
                  aria-label="Instructions for Applicant"
                  errorMsg={_get(formState.errors.recommendation_instructions_applicant, 'message') || ''}
                  placeholder={`Enter Instructions`}
                  required
                  label={`Instructions for Applicant`}
                  id={'recommendation_instructions_applicant'}
                  {...rest}
                />
              </Suspense>
            </InputContainer>
          )}
        />

        <Controller
          control={control}
          name="recommendation_instructions_recommender"
          render={({ field: { ref, ...rest } }) => (
            <InputContainer>
              <Suspense fallback={<div />}>
                <EditorDropdown
                  tabIndex="0"
                  title="+ Tokens"
                  onChange={addBodyToken}
                  type="body"
                  value={tokens && tokens?.data?.map((item) => {
                    return {
                      label: item?.attributes?.display_name,
                      value: item?.attributes?.placeholder
                    }
                  })}
                  dropdownStyles={{
                    position: "relative",
                    top: "185px",
                    left: navigator.platform.indexOf('Win') > -1 ? "248px" : "264px",
                    zIndex: "10000000",
                    background: "#ffffff",
                    width: "78px"
                }}
                menuLeftSpace={false}
                />

                <EditTextArea
                  aria-label="Instructions for Recommender Mailer"
                  errorMsg={_get(formState.errors.recommendation_instructions_recommender, 'message') || ''}
                  placeholder="Enter Instructions"
                  required
                  label="Instructions for Recommender"
                  id="recommendation_instructions_recommender"
                  setUpdateToken={sendBodyToken}
                  {...rest}
                />
              </Suspense>
            </InputContainer>
          )}
        />
        {data && data.length > 0 && (
          <Controller
            control={control}
            name={'recommendation_template_id'}
            render={({ field: { onChange, value, name, ref, ...rest } }) => (
              <DropdownContainer>
                <DropdownSelect
                  aria-label={'Select Recommendation template to Send'}
                  errorMsg={_get(formState.errors.recommendation_template_id, 'message') || ''}
                  id={'recommendation_template_id'}
                  label={'Select Recommendation to Send'}
                  options={data}
                  openSans
                  onChange={value => {
                    setValue('recommendation_template_id', value.toString(), {
                      shouldValidate: true,
                      shouldDirty: true,
                      shouldTouch: true,
                    });
                    onChange(value.toString());
                  }}
                  defaultValue={
                    edit
                      ? data.find(option => option.value.toString() === question.recommendation_template_id.toString())
                      : data.find(option => option.default === true)
                  }
                  {...rest}
                />
              </DropdownContainer>
            )}
          />
        )}
        <Controller
          control={control}
          name={'recommendation_required'}
          render={({ field: { onChange, register, ref, ...rest } }) => (
            <ToggleContainer>
              <Toggle
                id={'recommendation_required'}
                label={'Required'}
                onChange={value => {
                  setValue('recommendation_required', value);
                }}
                ref={register}
                {...rest}
              />
              <ToggleLabel>Required Field</ToggleLabel>
            </ToggleContainer>
          )}
        />
        <SubmitContainer>
          <AccentButtonMediumNunito
            aria-label={edit ? 'Save Recommendation' : 'Add Recommendation to Form'}
            form="recommendationAdd"
            type={'submit'}
            disabled={disableSubmit}
          >
            {disableSubmit ? <AccentButtonSpinner displayText="Saving ..." /> : edit ? 'Save' : 'Add to Form'}
            {!disableSubmit && <StyledIcon type="ChevronForward" color="#fff" />}
          </AccentButtonMediumNunito>
        </SubmitContainer>
      </form>
    </FormContainer>
  );
}
